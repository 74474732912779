import {atomWithStorage} from 'jotai/utils';
import {atom} from 'jotai';
import {appStore} from "./store";

const customStorage = {
    getItem: (key) => {
        if (!key) return null;
        const value = localStorage.getItem(key);
        if (!value || typeof value === 'undefined') return null;
        return value ? JSON.parse(value) : null;
    },
    setItem: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
};

// Declare the atom with a storage key, so it saves to localStorage
export const selectedProjectAtom = atomWithStorage('selectedProject', null, customStorage);
export const projectsModifiedAtom = atom(null);

// Function to get the project atom value
export const getSelectedProject = () => {
    return appStore.get(selectedProjectAtom);
};

// Function to set a new value for the project atom
export const setSelectedProject = (newProjectValue) => {
    appStore.set(selectedProjectAtom, newProjectValue); // Update the atom's value
};

export const dispatchProjectsModified = (event) => {
    appStore.set(projectsModifiedAtom, event); // Update the atom value
};
