import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import CustomImageField from "../components/fields/CustomImageField";
import {
  ImageInput,
  required,
  FileInput
} from "react-admin";
import {parse} from "query-string";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";
import RichTextInput from "ra-input-rich-text";
import CustomVideoField from "../components/fields/CustomVideoField";

export const ContentList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"contentType"}/>
    <FieldGuesser source={"title"}/>
    <FieldGuesser source={"subTitle"}/>
    <FieldGuesser source={"tags"}/>
    <FieldGuesser source={"category"}/>
    <FieldGuesser source={"videoUrl"}/>
  </RefreshableListBySelectedProject>
);

export const ContentCreate = props => {
  const {project: projectUri} = parse(props.location.search);
  let redirect = '/contents';

  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{project: projectUri}}>
      <InputGuesser source="contentType" validate={[required()]} fullWidth/>
      <InputGuesser source="title" validate={[required()]} fullWidth/>
      <InputGuesser source="subTitle" fullWidth/>
      {/*<InputGuesser source="body" multiline fullWidth/>*/}
      <RichTextInput source="body" theme={'snow'} multiline fullWidth
                     toolbar={[['bold', 'italic', 'underline', 'link', 'clean']]}/>
      <ImageInput source="image" label="Image" accept="image/*"
                  placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
      </ImageInput>
      <FileInput source="video" label="Video" accept="video/*"
                 placeholder={<p>Drop your file here</p>}>
        <CustomVideoField source="contentUrl"/>
      </FileInput>
      <InputGuesser source="videoUrl" fullWidth/>
      <InputGuesser source="tags" fullWidth/>
      <InputGuesser source="category" fullWidth/>
      <InputGuesser source="url" fullWidth/>
      <InputGuesser source="urlLabel" fullWidth/>
    </CreateGuesser>
  );
}
export const ContentEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="contentType" validate={[required()]} fullWidth/>
    <InputGuesser source="title" validate={[required()]} fullWidth/>
    <InputGuesser source="subTitle" fullWidth/>
    {/*<InputGuesser source="body" multiline fullWidth/>*/}
    <RichTextInput source="body" theme={'snow'} multiline fullWidth
                   toolbar={[['bold', 'italic', 'underline', 'link', 'clean']]}/>
    <ImageInput source="image" label="Image" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <FileInput source="video" label="Video" accept="video/*"
               placeholder={<p>Drop your file here</p>}>
      <CustomVideoField source="contentUrl"/>
    </FileInput>
    <InputGuesser source="videoUrl" fullWidth/>
    <InputGuesser source="tags" fullWidth/>
    <InputGuesser source="category" fullWidth/>
    <InputGuesser source="url" fullWidth/>
    <InputGuesser source="urlLabel" fullWidth/>
  </EditGuesser>
);
