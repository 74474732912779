import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  PasswordInput,
  Show,
  AutocompleteArrayInput,
  TextField,
  SimpleShowLayout,
  ReferenceInput,
  AutocompleteInput,
  required,
  ReferenceField,
  usePermissions,
} from "react-admin";
import isGranted from "../security";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

const roles = [
  {id: 'ROLE_USER', name: 'Role user'},
  {id: 'ROLE_DEVICE', name: 'Role device'},
  {id: 'ROLE_ADMIN', name: 'Role admin'},
  {id: 'ROLE_PROJECT_MANAGER', name: 'Role project managaer'},
  {id: 'ROLE_BRAND_MANAGER', name: 'Role brand managaer'},
];

export const UserList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"username"}/>
    <ReferenceField label="Account" source="account" reference="accounts">
      <TextField source="name"/>
    </ReferenceField>
  </RefreshableListBySelectedProject>
);

export const UserShow = props => {
  const {loaded, permissions} = usePermissions();
  if (!loaded) {
    return null;
  }
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="username"/>
        <TextField source="roles"/>
        {isGranted('ROLE_ADMIN', permissions) &&
          <ReferenceField label="Account" source="account" reference="accounts">
            <TextField source="name"/>
          </ReferenceField>}
        <ReferenceField label="Device" source="device" reference="devices">
          <TextField source="name"/>
          <TextField source="deviceUID"/>
        </ReferenceField>
        <ReferenceField label="Account" source="account" reference="accounts">
          <TextField source="name"/>
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
}


export const UserCreate = props => {
  const {loaded, permissions} = usePermissions();
  if (!loaded) {
    return null;
  }
  return (
    <CreateGuesser {...props}>
      <InputGuesser source="username" validate={[required()]}/>
      <PasswordInput source="password" validate={[required()]}/>
      <AutocompleteArrayInput source="roles" choices={roles} validate={[required()]}/>

      <ReferenceInput
        allowEmpty={true}
        source="device"
        reference="devices"
        label="Device"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      {isGranted('ROLE_ADMIN', permissions) &&
        <>
          <ReferenceInput
            allowEmpty={true}
            source="account"
            reference="accounts"
            label="Account"
            filterToQuery={searchText => ({name: searchText})}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>

          <ReferenceInput
            allowEmpty={true}
            source="project"
            reference="projects"
            label="Project"
            filterToQuery={searchText => ({name: searchText})}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        </>
      }

      <ReferenceInput
        allowEmpty={true}
        source="brand"
        reference="brands"
        label="Brand"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}


export const UserEdit = props => {
  const {loaded, permissions} = usePermissions();
  if (!loaded) {
    return null;
  }
  return (
    <EditGuesser {...props}>
      <InputGuesser source="username" validate={[required()]}/>
      <PasswordInput source="password"/>
      <AutocompleteArrayInput source="roles" choices={roles} validate={[required()]}/>

      <ReferenceInput
        allowEmpty={true}
        source="device"
        reference="devices"
        label="Device"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      {isGranted('ROLE_ADMIN', permissions) &&
        <>
          <ReferenceInput
            allowEmpty={true}
            source="account"
            reference="accounts"
            label="Account"
            filterToQuery={searchText => ({name: searchText})}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>

          <ReferenceInput
            allowEmpty={true}
            source="project"
            reference="projects"
            label="Project"
            filterToQuery={searchText => ({name: searchText})}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        </>
      }

      <ReferenceInput
        allowEmpty={true}
        source="brand"
        reference="brands"
        label="Brand"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </EditGuesser>
  );
}
