import {uploadImage, uploadVideo} from "../mediaUploader";

export async function handleContentResource(params) {
  let data = params.data;
  if (params.data.image && params.data.image.rawFile instanceof File) {
    data.image = (await uploadImage(params.data.image, false, data.project))['@id'];
  } else if (params.data.image && typeof params.data.image !== 'string') {
    data.image = data.image['@id'];
  }
  if (params.data.video && params.data.video.rawFile instanceof File) {
    data.video = (await uploadVideo(params.data.video))['@id'];
  } else if (params.data.video && typeof params.data.video !== 'string') {
    data.video = data.video['@id'];
  }
  return data;
}
