import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageInput,
  FileInput,
  SelectInput,
  NumberInput,
  TextInput,
  PasswordInput
} from "react-admin";

import CustomImageField from "../components/fields/CustomImageField";
import CustomDocumentField from "../components/fields/CustomDocumentField";
import OpenAiModelSelectInput from "../components/OpenAiModelSelectInput";
import { RefreshableListBySelectedProject } from "../RefreshableListBySelectedProject";
import GroqModelSelectInput from "../components/GroqModelSelectInput";

export const ShowyAiConfigList = (props) => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"type"}/>
    <FieldGuesser source={"model"}/>
    <FieldGuesser source={"toolChoice"}/>
    <FieldGuesser source={"temperature"}/>
    <FieldGuesser source={"language"}/>
    <FieldGuesser source={"groqModel"}/>
    <FieldGuesser source={"groqTemperature"}/>
    <FieldGuesser source={"sttModel"}/>
    <FieldGuesser source={"ttsVoice"}/>
    {/*<FieldGuesser source={"sessionTimeout"}/>*/}
    <FieldGuesser source={"disableSessionTimeout"}/>
    <FieldGuesser source={"toolDisplayTimeout"}/>
  </RefreshableListBySelectedProject>
);

const languages = [
  {id: "en", name: "en"},
  {id: "ar", name: "ar"},
  {id: "fr", name: "fr"},
];

const typeOptions = [
  // { id: "avatar", name: "Avatar" },
  { id: "voiceOnly", name: "Voice Only" },
];

/*const voiceProviders = [
  {id: "microsoft", name: "Microsoft"},
  {id: "amazon", name: "Amazon"},
  {id: "google", name: "google"},
  {id: "afflorithmics", name: "afflorithmics"},
  {id: "elevenlabs", name: "elevenlabs"},
  {id: "playHT", name: "playHT"},
];*/

const toolChoices = [
  {id: "auto", name: "Auto"},
  {id: "required", name: "Required"},
];

const speechModels = [{id: "whisper-1", name: "whisper-1"}];

const voices = [
  { id: "alloy", name: "alloy" },
  { id: "ash", name: "ash" },
  { id: "ballad", name: "ballad" },
  { id: "coral", name: "coral" },
  { id: "echo", name: "echo" },
  { id: "sage", name: "sage" },
  { id: "shimmer", name: "shimmer" },
  { id: "verse", name: "verse" },
];

export const ShowyAiConfigCreate = (props) => {
  return (
    <CreateGuesser {...props}>
      <InputGuesser source={"name"} validate={[required()]}/>
      <OpenAiModelSelectInput
        source="model"
        label="Model"
        defaultValue="gpt-4o"
      />
      <NumberInput
        source={"temperature"}
        validate={[required()]}
        defaultValue={0}
        min={0}
        max={1.2}
        step={0.1}
      />
      <SelectInput
        source="type"
        choices={typeOptions}
        validate={[required()]}
        defaultValue="voiceOnly"
      />
      <SelectInput
        source="language"
        choices={languages}
        validate={[required()]}
        defaultValue="en"
      />
      <InputGuesser
        source={"mainPrompt"}
        validate={[required()]}
        multiline
        fullWidth
      />

      <InputGuesser
        source={"persona"}
        validate={[required()]}
        multiline
        fullWidth
      />

      <InputGuesser
        source={"instructions"}
        // validate={[required()]}
        multiline
        fullWidth
      />

      <InputGuesser
        source={"knowledgeBase"}
        // validate={[required()]}
        multiline
        fullWidth
      />

      <InputGuesser
        source={"isMultilingual"}
        defaultValue={false}
        // validate={[required()]}
      />

      <InputGuesser
        source={"languageSelectionPrompt"}
        // validate={[required()]}
        multiline
        fullWidth
      />

      <TextInput source="languages"
                 parse={v => JSON.parse(v)}
                 format={v => JSON.stringify(v)}
                 fullWidth={true}
                 multiline={true}/>

      <SelectInput
        source="toolChoice"
        choices={toolChoices}
        validate={[required()]}
        defaultValue="required"
      />

      <PasswordInput
        source={"groqApiKey"}
        validate={[required()]}
        multiline
        fullWidth
      />
      <GroqModelSelectInput
        source="groqModel"
        label="Groq Model"
        defaultValue="llama-3.1-8b-instant"
      />
      <NumberInput
        source={"groqTemperature"}
        defaultValue={0}
        min={0}
        max={1.2}
        step={0.1}
      />
      <SelectInput
        source="sttModel"
        choices={speechModels}
        defaultValue="whisper-1"
      />
      <InputGuesser
        source="welcomeMessageTitle"
        toolbar={[
          ["bold", "italic", "underline", "clean"],
          [{direction: "ltr"}],
        ]}
      />
      <InputGuesser
        source="welcomeMessageParagraph"
        toolbar={[
          ["bold", "italic", "underline", "clean"],
          [{direction: "ltr"}],
        ]}
      />
      <ImageInput
        source="mainBackground"
        label="Main Background Image"
        accept="image/*"
        placeholder={<p>Drop your file here</p>}
      >
        <CustomImageField source="contentUrl"/>
      </ImageInput>
      <InputGuesser source={"sessionTimeout"} defaultValue={120}/>
      <InputGuesser source={"disableSessionTimeout"} defaultValue={false}/>
      <InputGuesser source={"toolDisplayTimeout"} defaultValue={20}/>
      <ReferenceArrayInput
        source="devices"
        reference="devices"
        label="Devices"
        filterToQuery={(searchText) => ({name: searchText})}
        filter={{"application.slug": "showy-ai"}}
      >
        <AutocompleteArrayInput optionText="name"/>
      </ReferenceArrayInput>
      <SelectInput
        source="ttsVoice"
        choices={voices}
        validate={[required()]}
        defaultValue="alloy"
      />
      <TextInput
        source="startVoiceConversationMessage"
        multiline
        fullWidth
      />
      <TextInput
        source="endVoiceConversationMessage"
        multiline
        fullWidth
      />
      <PasswordInput
        source="picoVoiceAccessKey"
        fullWidth
      />
      <FileInput
        source="porcupineModel"
        label="Porcupine Model"
        placeholder={<p>Drop your file here</p>}
      >
        <CustomDocumentField source="contentUrl"/>
      </FileInput>
      <TextInput
        source="porcupineKeyword"
        fullWidth
      />
      <PasswordInput
        source="openAiApiKey"
        fullWidth
      />
    </CreateGuesser>
  );
};

export const ShowyAiConfigEdit = (props) => {
  return (
    <EditGuesser {...props}>
      <InputGuesser source={"name"} validate={[required()]}/>
      <OpenAiModelSelectInput
        source="model"
        label="Model"
        defaultValue="gpt-4o"
      />
      <NumberInput
        source={"temperature"}
        validate={[required()]}
        defaultValue={0}
        min={0}
        max={1.2}
        step={0.1}
      />
      <SelectInput
        source="type"
        choices={typeOptions}
        validate={[required()]}
        defaultValue="voiceOnly"
      />
      <SelectInput
        source="language"
        choices={languages}
        validate={[required()]}
        defaultValue="en"
      />
      <InputGuesser
        source={"mainPrompt"}
        validate={[required()]}
        multiline
        fullWidth
      />
      <SelectInput
        source="toolChoice"
        choices={toolChoices}
        validate={[required()]}
        defaultValue="required"
      />

      <InputGuesser
        source={"persona"}
        validate={[required()]}
        multiline
        fullWidth
      />

      <InputGuesser
        source={"instructions"}
        // validate={[required()]}
        multiline
        fullWidth
      />

      <InputGuesser
        source={"knowledgeBase"}
        // validate={[required()]}
        multiline
        fullWidth
      />

      <InputGuesser
        source={"isMultilingual"}
        defaultValue={false}
        // validate={[required()]}
      />

      <InputGuesser
        source={"languageSelectionPrompt"}
        // validate={[required()]}
        multiline
        fullWidth
      />

      <TextInput source="languages"
                 parse={v => JSON.parse(v)}
                 format={v => JSON.stringify(v)}
                 fullWidth={true}
                 multiline={true}/>

      <PasswordInput
        source={"groqApiKey"}
        validate={[required()]}
        multiline
        fullWidth
      />
      <GroqModelSelectInput
        source="groqModel"
        label="Groq Model"
        defaultValue="llama-3.1-8b-instant"
      />
      <NumberInput
        source={"groqTemperature"}
        defaultValue={0}
        min={0}
        max={1.2}
        step={0.1}
      />

      <SelectInput
        source="sttModel"
        choices={speechModels}
        defaultValue="whisper-1"
      />
      <InputGuesser
        source="welcomeMessageTitle"
        toolbar={[
          ["bold", "italic", "underline", "clean"],
          [{direction: "ltr"}],
        ]}
      />
      <InputGuesser
        source="welcomeMessageParagraph"
        toolbar={[
          ["bold", "italic", "underline", "clean"],
          [{direction: "ltr"}],
        ]}
      />
      <ImageInput
        source="mainBackground"
        label="Main Background Image"
        accept="image/*"
        placeholder={<p>Drop your file here</p>}
      >
        <CustomImageField source="contentUrl"/>
      </ImageInput>
      <InputGuesser source={"sessionTimeout"}/>
      <InputGuesser source={"disableSessionTimeout"} defaultValue={false}/>
      <InputGuesser source={"toolDisplayTimeout"}/>
      <ReferenceArrayInput
        source="devices"
        reference="devices"
        label="Devices"
        filterToQuery={(searchText) => ({name: searchText})}
        filter={{"application.slug": "showy-ai"}}
      >
        <AutocompleteArrayInput optionText="name"/>
      </ReferenceArrayInput>
      <SelectInput
        source="ttsVoice"
        choices={voices}
        validate={[required()]}
        defaultValue="alloy"
      />
      <TextInput
        source="startVoiceConversationMessage"
        multiline
        fullWidth
      />
      <TextInput
        source="endVoiceConversationMessage"
        multiline
        fullWidth
      />
      <PasswordInput
        source="picoVoiceAccessKey"
        fullWidth
      />
      <FileInput
        source="porcupineModel"
        label="Porcupine Model"
        placeholder={<p>Drop your file here</p>}
      >
        <CustomDocumentField source="contentUrl"/>
      </FileInput>
      <TextInput
        source="porcupineKeyword"
        fullWidth
      />
      <PasswordInput
        source="openAiApiKey"
        fullWidth
      />
    </EditGuesser>
  );
};
